import RouterConstants from "@constants/RouterConstants";
import { useAppStore } from "@hooks/useStores";
import PageLayout from "@layouts/PageLayout";
import { Creator as CreatorInterface } from "@models/videos";
import { UploadFileRounded } from "@mui/icons-material";
import { Autocomplete, Box, Button, TextField, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styles from "./VideoUpload.module.scss";

export type EditCreatorState = {
	CreatorToEdit?: CreatorInterface;
};

const VideoUpload = () => {
	const { productStore } = useAppStore();
	const { t } = useTranslation("translation", { keyPrefix: "videoUpload" });
	const navigate = useNavigate();
	const [videoFile, setVideoFile] = useState<File | null>(null);
	const [videoPreview, setVideoPreview] = useState<string | null>(null);
	const [videoName, setVideoName] = useState<string>("");

	const [selectedProducts, setSelectedProducts] = useState<any[]>([]);
	const [searchResults, setSearchResults] = useState<any[]>([]);
	const [searchQuery, setSearchQuery] = useState("");
	const [loadingProductsSearch, setLoadingProductsSearch] = useState(false);

	// Handle file selection
	const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const file = event.target.files?.[0];
		if (file && file.type.startsWith("video/")) {
			setVideoFile(file);
			setVideoPreview(URL.createObjectURL(file));
		}
	};

	// Handle form submission
	const handleSubmit = () => {
		console.log("Uploading:", {
			videoName,
			videoFile,
			selectedProducts,
		});

		// Navigate back to videos page after upload
		navigate(RouterConstants.VIDEOS.path);
	};

	const handleProductSelect = async (_event: any, value: any) => {
		if (value) {
			setSearchQuery("");
			try {
				const selectedProductIds = value.map((product: any) => product.id);
				setSelectedProducts(selectedProductIds);
			} catch (error) {
				console.error("Error fetching product:", error);
			}
		}
	};

	const searchProducts = async (query: any) => {
		setLoadingProductsSearch(true);
		productStore
			.searchProducts({ query: query || "" })
			.then((response: any) => {
				const result = (response?.products || []).map((product: any) => ({
					label: product?.name?.default ?? product.title,
					id: product?.id,
				}));

				setSearchResults(result);
			})
			.finally(() => {
				setLoadingProductsSearch(false);
			});
	};

	return (
		<PageLayout title={t("uploadVideo")} showBackBtn>
			<Box className={styles.container}>
				{/* Video Upload Input */}
				{!videoPreview && (
					<label className={styles.uploadVideoInput}>
						<UploadFileRounded />
						<Typography className={styles.title}>{t("uploadInput.title")}</Typography>
						<Typography className={styles.label}>{t("uploadInput.maxSize")}</Typography>
						<Typography className={styles.label}>{t("uploadInput.supportedFormats")}</Typography>
						<input type="file" accept="video/*" onChange={handleFileChange} style={{ display: "none" }} />
					</label>
				)}

				{/* Video Preview */}
				{videoPreview && (
					<Box>
						<video controls src={videoPreview} style={{ width: "100%" }} />
					</Box>
				)}

				{/* Video Name Field */}
				<TextField
					label={t("videoName")}
					variant="outlined"
					fullWidth
					value={videoName}
					onChange={(e) => setVideoName(e.target.value)}
				/>

				{/* Product Selection */}
				<Box className={styles.productsListWrapper}>
					<Autocomplete
						className={styles.productsListField}
						options={searchResults}
						getOptionLabel={(option: any) => option.label}
						onChange={handleProductSelect}
						inputValue={searchQuery}
						onInputChange={(_event, newInputValue) => {
							setSearchQuery(newInputValue);
							searchProducts(newInputValue);
						}}
						loading={loadingProductsSearch}
						renderInput={(params) => (
							<TextField {...params} label={t("productsInput")} variant="outlined" />
						)}
						multiple
					/>
				</Box>

				{/* Submit Button */}
				<Button
					variant="contained"
					color="primary"
					fullWidth
					onClick={handleSubmit}
					disabled={!videoFile || !videoName}
					className={styles.uploadButton}
				>
					{t("uploadVideo")}
				</Button>
			</Box>
		</PageLayout>
	);
};

export default observer(VideoUpload);

import { PredefinedDateRanges, UsagePeriod } from "@models/usage.ts";
import { Typography } from "@mui/material";
import clsx from "clsx";
import { isAfter, subYears } from "date-fns";
import React from "react";
import { DateRangePicker, DatePicker } from "rsuite";
import "rsuite/DateRangePicker/styles/index.css";
import "rsuite/DatePicker/styles/index.css";
import styled from "styled-components";

const StyledDateRangePicker = styled.div`
	.rs-input-group {
		height: 32px;
		width: 220px;
		border: 1px solid #b3b3b9;
	}

	.rs-input-group:not(.rs-input-group-disabled):hover,
	.rs-input-group:not(.rs-input-group-disabled).rs-input-group-focus {
		border-color: var(--primary);
	}

	.rs-input-group:focus-within {
		border-color: var(--primary);
		outline: none;
	}

	input::placeholder {
		font-size: 14px;
		color: #1a0638;
		font-style: normal;
		font-weight: 400;
		line-height: 150%;
	}

	.without-icon {
		.rs-input-group {
			width: 180px;
		}
		.rs-input-group-addon {
			display: none;
		}
	}

	input {
		pointer-events: none;
	}

	position: relative;
`;

interface IProps {
	loading?: boolean;
	isRangePicker?: boolean; // New prop to toggle between range and single date picker
	value?: [Date, Date] | Date | undefined;
	hoverRange?: UsagePeriod.Weekly | UsagePeriod.Monthly | ((date: Date) => [Date, Date]);
	onChange: (props: any) => void;
	cleanable: boolean;
	className?: string;
	label?: string;
	labelClassName?: string;
	disabled?: boolean;
}

const CustomDateRangePicker: React.FC<IProps> = ({
	loading,
	isRangePicker = true, // Default to range picker
	value,
	hoverRange,
	cleanable = true,
	className,
	label,
	labelClassName,
	disabled,
	...rest
}) => {
	const oneYearAgo = subYears(new Date(), 3);

	return (
		<StyledDateRangePicker>
			{label && <Typography className={labelClassName}>{label}</Typography>}
			{isRangePicker ? (
				<DateRangePicker
					ranges={PredefinedDateRanges}
					showHeader={false}
					placement={"bottomEnd"}
					disabled={loading || disabled}
					loading={loading}
					value={value as [Date, Date] | undefined}
					hoverRange={hoverRange}
					shouldDisableDate={(date) => {
						// Disable if date is after today or before one year ago
						if (isAfter(date, new Date()) || isAfter(oneYearAgo, date)) {
							return true;
						}
						return false;
					}}
					cleanable={cleanable}
					className={clsx(className, {
						"without-icon": !cleanable && !loading,
					})}
					{...rest}
				/>
			) : (
				<DatePicker
					placement={"bottomEnd"}
					disabled={loading || disabled}
					loading={loading}
					value={value as Date | undefined}
					shouldDisableDate={(date) => {
						// Disable if date is after today or before one year ago
						if (isAfter(date, new Date()) || isAfter(oneYearAgo, date)) {
							return true;
						}
						return false;
					}}
					cleanable={cleanable}
					className={clsx(className, {
						"without-icon": !cleanable && !loading,
					})}
					{...rest}
				/>
			)}
		</StyledDateRangePicker>
	);
};

export default CustomDateRangePicker;

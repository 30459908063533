import { modalStyles } from "@assets/styles/ModalStyles";
import ConfirmationPopup from "@components/common/ConfirmationPopup";
import VideoPlayer from "@components/common/VideoPlayer/VideoPlayer";
import RouterConstants from "@constants/RouterConstants";
import { useAppStore } from "@hooks/useStores";
import { useVideosLogic } from "@hooks/useVideosLogic";
import PageLayout from "@layouts/PageLayout";
import TableLayout from "@layouts/TableLayout/TableLayout";
import { UploadRounded } from "@mui/icons-material";
import { Box, Button, Modal } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import CustomFilters from "./CustomFilters";
import styles from "./Videos.module.scss";

const Videos = () => {
	const { t: ct } = useTranslation("translation");
	const { t } = useTranslation("translation", { keyPrefix: "videos.table" });
	const {
		headerArray,
		tableData,
		closeDeletePopup,
		openDeletePopup,
		deleteVideo,
		openVideoModal,
		closeVideoPopup,
		selectedVideo,
		paginationOptions,
		searchVideos,
		searchLoading,
		getVideos,
	} = useVideosLogic();

	const { videosStore } = useAppStore();
	const navigate = useNavigate();

	const handleFilterChange = () => {
		const { postedAt, updatedAt } = videosStore.filters;
		getVideos(undefined, postedAt, updatedAt);
	};

	return (
		<PageLayout
			title={t("titlePage")}
			actions={
				<Box className={styles.actionsHeader}>
					<Button
						variant="contained"
						onClick={() => {
							navigate(RouterConstants.VIDEO_UPLOAD.path);
						}}
						className={styles.actionBtn}
					>
						<UploadRounded /> {t("uploadVideo")}
					</Button>
				</Box>
			}
		>
			<Box className={styles.container}>
				<TableLayout
					// title={t("title")}
					enableSearch={false}
					headerArray={headerArray}
					tableData={tableData}
					hideSecondaryButton={true}
					className={styles.tableLayoutStyle}
					paginationOptions={paginationOptions}
					handleSearchChange={searchVideos}
					searchLoading={searchLoading}
					customFilters={<CustomFilters onFilterChange={handleFilterChange} />}
					loading={searchLoading}
					sortable
				/>
				<ConfirmationPopup
					onClose={closeDeletePopup}
					title={t("deletePopup.title")}
					description={t("deletePopup.description")}
					confirmButtonText={ct("delete")}
					open={openDeletePopup}
					onClick={deleteVideo}
				/>
				{openVideoModal && (
					<Modal
						open={openVideoModal}
						onClose={closeVideoPopup}
						aria-labelledby="video-modal-title"
						aria-describedby="video-modal-description"
					>
						<Box sx={modalStyles.modalContainer}>
							{selectedVideo && (
								<VideoPlayer url={selectedVideo.url} onClose={closeVideoPopup} autoplay={true} />
							)}
						</Box>
					</Modal>
				)}
			</Box>
		</PageLayout>
	);
};

export default observer(Videos);

import ShowAt from "@components/common/ShowAt/ShowAt.tsx";
import { getClientConfig } from "@config/clients";
import { Environment } from "@constants/Environments";
import RouterConstants from "@constants/RouterConstants.ts";
import { useAppStore } from "@hooks/useStores";
import environmentStore from "@mobx/EnvironmentStore";
import { ArrowDropDown, PersonOutline } from "@mui/icons-material";
import {
	AppBar,
	FormControl,
	IconButton,
	Menu,
	MenuItem,
	Select,
	SelectChangeEvent,
	Toolbar,
	Typography,
} from "@mui/material";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styles from "./Header.module.scss";

const Header: React.FC = () => {
	const { t } = useTranslation("translation");
	const navigate = useNavigate();
	const { authStore } = useAppStore();
	const [profileMenu, setProfileMenu] = useState<null | HTMLElement>(null);
	const [environment, setEnvironment] = useState(environmentStore.environment);
	const clientConfig = getClientConfig();

	useEffect(() => {
		const storedEnvironment = localStorage.getItem("environment");
		if (storedEnvironment && Object.values(Environment).includes(storedEnvironment as Environment)) {
			setEnvironment(storedEnvironment as Environment);
		}
	}, [setEnvironment]);

	const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
		setProfileMenu(event.currentTarget);
	};

	const handleMenuClose = () => {
		setProfileMenu(null);
	};

	const handleLogout = async () => {
		await authStore.logout();
		navigate("/login");
	};

	const handleEnvironmentChange = (event: SelectChangeEvent) => {
		const selectedEnvironment = event.target.value as Environment;
		setEnvironment(selectedEnvironment);
		environmentStore.setEnvironment(selectedEnvironment);
	};

	return (
		<AppBar position="sticky" className={styles.appBar}>
			<Toolbar>
				<div className={styles.headerLogoContainer}>
					<Typography
						variant="h6"
						component="div"
						className={styles.headerTitle}
						onClick={() => navigate(RouterConstants.HOME.path)}
					>
						<img src={clientConfig.logo} alt={clientConfig.title} style={{ marginRight: 10, height: 30 }} />
						{t("header.socialVideoAnalyzer")}
					</Typography>
					{clientConfig.isReconess && (
						<>
							<Typography variant={"body1"} color="textSecondary" className={styles.orgNameSeparator}>
								|
							</Typography>
							<Typography variant={"body1"} color="textSecondary" className={styles.orgName}>
								{authStore.user?.organization.name}
							</Typography>
						</>
					)}
				</div>
				<ShowAt condition={false}>
					<FormControl size="small">
						<Select value={environment} onChange={handleEnvironmentChange}>
							{Object.values(Environment).map((env) => (
								<MenuItem key={env} value={env}>
									{t(`common.${env}`)}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</ShowAt>
				<div className={styles.profileMenu}>
					<IconButton
						aria-label="account of current user"
						aria-controls="menu-appbar"
						aria-haspopup="true"
						onClick={handleMenuOpen}
					>
						<PersonOutline />
						<Typography className={styles.userName} variant={"body1"} color="textSecondary">
							{authStore.user?.first_name} {authStore.user?.last_name}
						</Typography>
						<ArrowDropDown />
					</IconButton>
					<Menu
						id="menu-appbar"
						anchorEl={profileMenu}
						keepMounted
						open={Boolean(profileMenu)}
						onClose={handleMenuClose}
					>
						<MenuItem onClick={handleLogout}>{t("common.logout")}</MenuItem>
					</Menu>
				</div>
			</Toolbar>
		</AppBar>
	);
};

export default observer(Header);

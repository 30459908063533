import { getClientConfig } from "@config/clients";
import useErrorToast from "@hooks/useErrorToast.tsx";
import { useAppStore } from "@hooks/useStores.tsx";
import { Button, TextField, Typography } from "@mui/material";
import { observer } from "mobx-react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styles from "./Login.module.scss";

const Login: React.FC = observer(() => {
	const { t } = useTranslation("translation");
	const navigate = useNavigate();
	const { authStore } = useAppStore();
	const { showError } = useErrorToast();
	const clientConfig = getClientConfig();
	const [submitting, setSubmitting] = useState<boolean>(false);
	const [email, setEmail] = useState<string>("");
	const [password, setPassword] = useState<string>("");

	const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		setSubmitting(true);
		try {
			await authStore.authenticate(email, password);
			navigate("/");
		} catch (error: any) {
			showError(error?.response?.data?.message);
		} finally {
			setSubmitting(false);
		}
	};

	return (
		<div className={styles.loginWrapper}>
			<div className={styles.logoContainer}>
				<img src={clientConfig.logo} alt={clientConfig.title} className={styles.formLogo} />
				<Typography variant="h6">{t("header.socialVideoAnalyzer")}</Typography>
			</div>
			<form onSubmit={handleSubmit} className={styles.loginForm}>
				<TextField
					label={t("common.email")}
					type="email"
					variant="outlined"
					value={email}
					onChange={(e) => setEmail(e.target.value)}
					required
					autoComplete="user-name"
				/>
				<TextField
					label={t("common.password")}
					type="password"
					variant="outlined"
					value={password}
					onChange={(e) => setPassword(e.target.value)}
					required
					autoComplete="current-password"
				/>
				<Button type="submit" variant="contained" color="primary" disabled={submitting}>
					{t("common.login")}
				</Button>
			</form>
		</div>
	);
});

export default Login;

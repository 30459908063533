import "@assets/styles/main.scss";
import { getClientConfig } from "@config/clients";
import RouterConstants from "@constants/RouterConstants.ts";
import AppLayout from "@layouts/AppLayout/AppLayout";
import AuthLayout from "@layouts/AuthLayout/AuthLayout";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Login from "@pages/auth/Login/Login";
import Creator from "@pages/Creator/Creator";
import Creators from "@pages/Creators/Creators";
import CreatorsInsights from "@pages/CreatorsInsights/CreatorsInsights";
import Home from "@pages/Home/Home.tsx";
import Insights from "@pages/Insights/Insights";
import Integrations from "@pages/Integrations/Integrations";
import ProductsInsights from "@pages/ProductsInsights/ProductsInsights";
import Settings from "@pages/Settings/Settings";
import Video from "@pages/Video/Video";
import Videos from "@pages/Videos/Videos";
import Widget from "@pages/Widget/Widget";
import Widgets from "@pages/Widgets/Widgets";
import { useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "./App.scss";
import VideoUpload from "@pages/VideoUpload/VideoUpload";


// Function to create a theme dynamically
const generateTheme = () => {
	const { primaryColor, primaryTextColor, primaryRGB, primaryDarkColor } = getClientConfig();

	document.documentElement.style.setProperty("--primary", primaryColor);
	document.documentElement.style.setProperty("--primary-rgb", primaryRGB);
	document.documentElement.style.setProperty("--primary-text-color", primaryTextColor);
	document.documentElement.style.setProperty("--primary-light-color", primaryColor);
	document.documentElement.style.setProperty("--primary-dark-color", primaryDarkColor);

	return createTheme({
		palette: {
			primary: {
				main: primaryColor,
			},
		},
		typography: {
			fontFamily: "Helvetica, sans-serif",
		},
	});
};

function App() {
	const theme = generateTheme();
	const { title, favicon, metaDescription } = getClientConfig();

	useEffect(() => {
		// Update page title
		document.title = title;

		// Update meta description
		const metaDesc = document.querySelector("meta[name='description']");
		if (metaDesc) {
			metaDesc.setAttribute("content", metaDescription);
		} else {
			const meta = document.createElement("meta");
			meta.name = "description";
			meta.content = metaDescription;
			document.head.appendChild(meta);
		}

		// Update Open Graph tags
		const ogTitle = document.querySelector("meta[property='og:title']");
		const ogDesc = document.querySelector("meta[property='og:description']");
		if (ogTitle) ogTitle.setAttribute("content", title);
		if (ogDesc) ogDesc.setAttribute("content", metaDescription);

		// Update Twitter tags
		const twitterTitle = document.querySelector("meta[name='twitter:title']");
		const twitterDesc = document.querySelector("meta[name='twitter:description']");
		if (twitterTitle) twitterTitle.setAttribute("content", title);
		if (twitterDesc) twitterDesc.setAttribute("content", metaDescription);

		// Update favicon
		const link = document.querySelector("link[rel='icon']");
		if (link) {
			link.setAttribute("href", favicon);
		} else {
			const newFavicon = document.createElement("link");
			newFavicon.rel = "icon";
			newFavicon.href = favicon;
			document.head.appendChild(newFavicon);
		}

	}, [title, favicon, metaDescription]);

	return (
		<ThemeProvider theme={theme}>
			<BrowserRouter>
				<Routes>
					<Route element={<AppLayout />}>
						<Route path={RouterConstants.HOME.path} element={<Home />} />
						<Route path={RouterConstants.INSIGHTS.path} element={<Insights />} />
						<Route path={RouterConstants.CREATORS_INSIGHTS.path} element={<CreatorsInsights />} />
						<Route path={RouterConstants.PRODUCTS_INSIGHTS.path} element={<ProductsInsights />} />
						<Route path={RouterConstants.WIDGETS.path} element={<Widgets />} />
						<Route path={`${RouterConstants.WIDGET.path}/:id?`} element={<Widget />} />
						<Route path={RouterConstants.VIDEOS.path} element={<Videos />} />
						<Route path={RouterConstants.CREATORS.path} element={<Creators />} />
						<Route path={`${RouterConstants.CREATOR.path}/:id`} element={<Creator />} />
						<Route path={`${RouterConstants.VIDEO.path}/:id?`} element={<Video />} />
						<Route path={RouterConstants.INTEGRATIONS.path} element={<Integrations />} />
						<Route path={RouterConstants.SETTINGS.path} element={<Settings />} />
						<Route path={RouterConstants.VIDEO_UPLOAD.path} element={<VideoUpload />} />
					</Route>
					<Route element={<AuthLayout />}>
						<Route path={RouterConstants.LOGIN.path} element={<Login />} />
					</Route>
				</Routes>
			</BrowserRouter>
			<ToastContainer />
		</ThemeProvider>
	);
}

export default App;

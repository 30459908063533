import { Video } from "@models/videos";
import QueryService from "@utils/QueryService.ts";
import { makeAutoObservable, runInAction } from "mobx";

export interface IVideosStore {
	totalRows?: number;
	videos: any[];
	isError: boolean;
	isLoading: boolean;

	getVideos({
		perPage,
		page,
		query,
	}: {
		perPage?: number;
		page?: number;
		query?: string;
		creatorId?: string | undefined;
		tiktokerName?: string;
		status?: string;
		name?: string;
		sentiment?: string;
		emotion?: string;
		postedAt?: [Date, Date];
		updatedAt?: [Date, Date];
	}): Promise<void>;

	getVideo(videoId: string): Promise<Video>;

	updateVideo(videoId: string, videoData: any): Promise<void>;

	deleteVideo(videoId: string): Promise<void>;

	filters: any;

	setFilter(key: string, value: any): void;
}

export default class VideosStore implements IVideosStore {
	queryService = new QueryService("/video");
	videos: any[] = [];
	totalRows?: number = 0;
	isError: boolean = false;
	isLoading: boolean = false;

	filters: any = {
		tiktokerName: "",
		status: "all",
		sentiment: "all",
		emotion: "all",
		postedAt: new Date(),
		updatedAt: new Date(),
	};

	setFilter = (key: string, value: any) => {
		this.filters[key] = value;
	};

	constructor() {
		makeAutoObservable(this);
	}

	getVideos = async ({
		perPage = 10,
		page = 1,
		query = "",
		tiktokerName,
	}: {
		perPage?: number;
		page?: number;
		query?: string;
		creatorId?: string | undefined;
		tiktokerName?: string;
		status?: string;
		name?: string;
		sentiment?: string;
		emotion?: string;
		postedAt?: [Date, Date];
		updatedAt?: [Date, Date];
	}): Promise<void> => {
		try {
			this.isError = false;
			this.isLoading = true;

			// Build the query string dynamically
			let queryParams = `/?per_page=${perPage}&page=${page}`;

			if (query) {
				queryParams += `&query=${encodeURIComponent(query)}`;
			}

			if (this.filters.tiktokerName?.length > 0) {
				queryParams += `&tiktokerName=${encodeURIComponent(this.filters.tiktokerName)}`;
			}
			if (tiktokerName && tiktokerName.length > 0) {
				queryParams += `&tiktokerName=${encodeURIComponent(tiktokerName)}`;
			}

			if (this.filters.status && this.filters.status !== "all") {
				queryParams += `&status=${encodeURIComponent(this.filters.status)}`;
			}

			if (this.filters.sentiment && this.filters.sentiment !== "all") {
				queryParams += `&sentiment=${encodeURIComponent(this.filters.sentiment)}`;
			}

			if (this.filters.emotion && this.filters.emotion !== "all") {
				queryParams += `&emotion=${encodeURIComponent(this.filters.emotion)}`;
			}

			// if (postedAt) {
			// 	const startDate = formatDate(postedAt[0], "start");
			// 	// const endDate = formatDate(postedAt[1], "end");
			// 	queryParams += `&postedAt=${encodeURIComponent(`${startDate}`)}`;
			// }
			//
			// if (updatedAt) {
			// 	const startDate = formatDate(updatedAt[0], "start");
			// 	// const endDate = formatDate(updatedAt[1], "end");
			// 	queryParams += `&updatedAt=${encodeURIComponent(`${startDate}`)}`;
			// }

			const response = await this.queryService.get(queryParams);

			runInAction(() => {
				this.videos = response.items;
				this.totalRows = response.total;
				this.isError = false;
				this.isLoading = false;
			});
		} catch (error) {
			console.error("Error fetching videos:", error);
			runInAction(() => {
				this.isLoading = false;
				this.isError = true;
			});
		}
	};

	getVideo = async (videoId: string): Promise<Video> => {
		try {
			this.isError = false;
			// this.isLoading = true;
			const video = await this.queryService.get(`/${videoId}`);
			return video;
		} catch (error) {
			console.error("Error fetching video:", error);
			runInAction(() => {
				// this.isLoading = false;
				this.isError = true;
			});
			throw error;
		}
	};

	updateVideo = async (videoId: string, videoData: any): Promise<void> => {
		try {
			this.isError = false;
			this.isLoading = true;
			await this.queryService.put(`/${videoId}`, videoData);
			runInAction(() => {
				this.isLoading = false;
			});
		} catch (error) {
			console.error("Error updating video:", error);
			runInAction(() => {
				this.isLoading = false;
				this.isError = true;
			});
			throw error;
		}
	};

	deleteVideo = async (videoId: string): Promise<void> => {
		try {
			this.isError = false;
			this.isLoading = true;

			await this.queryService.delete(`/${videoId}`);
			runInAction(() => {
				this.isLoading = false;
			});
		} catch (error) {
			console.error("Error deleting video:", error);
			runInAction(() => {
				this.isLoading = false;
				this.isError = true;
			});
		}
	};
}

import ReconessLogo from "@assets/images/RLogo.png";
import OneSizeLogo from "@assets/images/onesize_logo.png";
import HudaBeautyLogo from "@assets/images/svgs/huda_beauty_logo.svg";

interface ClientConfigType {
	hostnamePatterns?: string[];
	primaryColor: string;
	primaryTextColor: string;
	primaryRGB: string;
	primaryDarkColor: string;
	logo: string;
	title: string;
	favicon: string;
	metaDescription: string;
	isReconess?: boolean;
}

const CLIENT_CONFIG: Record<string, ClientConfigType> = {
	huda: {
		hostnamePatterns: ["hb-sva.reconess.com", "hb-stg.sva.reconess.com"],
		primaryColor: "#e31870",
		primaryTextColor: "#212529",
		primaryRGB: "227, 24, 112",
		primaryDarkColor: "#b31359",
		logo: HudaBeautyLogo,
		title: "Huda Beauty | Social Scout",
		favicon: "favicon/huda-beauty.ico",
		metaDescription:
			"Utilize Reconess' latest AI technology to collect and analyze social videos and selectively show them in your storefront in a few clicks.",
	},
	onesize: {
		hostnamePatterns: ["onesize-stg.sva.reconess.com", "onesize-sva.reconess.com"],
		primaryColor: "#b90b32",
		primaryTextColor: "#191919",
		primaryRGB: "159, 9, 43",
		primaryDarkColor: "#9f092b",
		logo: OneSizeLogo,
		title: "One Size | Social Scout",
		favicon: "favicon/one-size-favicon.png",
		metaDescription:
			"OneSize provides AI-driven social media video analysis to help brands make data-driven decisions.",
	},
	default: {
		primaryColor: "#1f35c4",
		primaryTextColor: "#212529",
		primaryRGB: "227, 24, 112",
		primaryDarkColor: "#03034a",
		logo: ReconessLogo,
		title: "Reconess | Social Scout",
		favicon: "favicon/reconess.ico",
		metaDescription: "Reconess AI technology helps brands analyze and optimize social media video content.",
		isReconess: true,
	},
};

export const getClientConfig = (): ClientConfigType => {
	const hostname = window.location.hostname;
	for (const client of Object.values(CLIENT_CONFIG)) {
		if (client.hostnamePatterns && client.hostnamePatterns.includes(hostname)) {
			return client;
		}
	}
	return CLIENT_CONFIG.default;
};
